@import '../../scss/index.scss';
.container__map {
    z-index: 1;
    background-color: white;
    width: 100%;
    height: 600px;
    .container {
        display: flex;
        height: 60px;
        p {
            margin-top: auto;
            margin-bottom: auto;
            font-family: $gotica;
            span {
                font-weight: bold;
            }
        }
    }
    @media screen and( max-width: $media-breackpoint-up-md) {
        height: 600px;
        .container {
            height: 100px;
        }
    }
}