@import '../../scss/index.scss';
.espacio404 {
    height: 100px;
}

.error {
    height: 80vh;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
    .image {
        justify-content: center;
        align-items: center;
        display: flex;
        img {
            margin-left: auto;
            margin-right: auto;
            width: 400px;
        }
    }
    .texto {
        justify-content: center;
        align-items: center;
        display: flex;
        p {
            text-align: center;
            font-family: $gotica;
        }
    }
    .error404 {
        justify-content: center;
        align-items: center;
        display: flex;
        h1 {
            font-size: 4em;
            font-weight: bold;
            text-align: center;
            font-family: $gotica;
        }
    }
    .button {
        display: flex;
        align-items: center;
        justify-content: center;
        a {
            text-decoration: none;
            .enlace {
                border-radius: 20px;
                width: 150px;
                height: 40px;
                background-color: #2594d1;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                p {
                    text-decoration: none;
                    color: white;
                    font-family: $gotica;
                    font-weight: bold;
                    margin-top: auto;
                    margin-bottom: auto;
                }
            }
        }
    }
    @media screen and( max-width: $media-breackpoint-up-lg) {
        height: 100vh;
    }
    @media screen and( max-width: $media-breackpoint-up-sm) {
        height: 80vh;
        .image {
            justify-content: center;
            align-items: center;
            display: flex;
            img {
                margin-top: 20px;
                margin-left: auto;
                margin-right: auto;
                width: 250px;
            }
        }
        .texto {
            padding-left: 10%;
            padding-right: 10%;
        }
    }
    @media screen and( max-width: $media-breackpoint-up-xs) {
        height: 100vh;
    }
}