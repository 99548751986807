@import '../../scss/index.scss';
*::before {
    margin: 0;
    box-sizing: border-box;
}

.nav {
    width: 100% !important;
    height: 70px;
    display: flex;
    position: fixed;
    justify-content: space-between;
    background-color: #2594d1;
    z-index: 300;
    .container {
        height: 70px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        a {
            display: flex;
            img {
                margin-top: auto;
                margin-bottom: auto;
                width: 100px;
            }
        }
        .nav__menu {
            display: flex;
            justify-content: space-around;
            gap: 3rem;
            margin-top: auto;
            margin-bottom: auto;
            li {
                list-style: none;
                display: flex;
                a {
                    font-weight: bold;
                    font-family: $gotica;
                    margin-top: auto;
                    margin-bottom: auto;
                    text-decoration: none;
                    color: white;
                }
            }
        }
        .nav__toggler div {
            width: 2.5rem;
            height: 0.2rem;
            margin: 0.4rem;
            background: white;
        }
        .nav__toggler {
            cursor: pointer;
            display: none;
            transition: 0.5s ease-in;
        }
    }
    .nav__item {
        a {
            &:hover {
                cursor: pointer;
            }
        }
    }
}

@media screen and( max-width: $media-breackpoint-up-lg) {
    .nav {
        .container {
            .nav__menu {
                position: fixed;
                top: 55px;
                right: 0;
                width: 50%;
                height: 100%;
                background-color: #2594d1;
                flex-direction: column;
                transform: translateX(100%);
                transition: 0.5s ease-in-out;
                opacity: 0%;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 100;
                li {
                    a {
                        font-size: 20px;
                        &:hover {
                            cursor: pointer;
                        }
                    }
                }
            }
            .nav__toggler {
                display: block;
                transition: 0.5s ease-in;
            }
            /* navbar active */
            .nav__active {
                transform: translateX(0);
                transition: 0.5s ease-in-out;
                opacity: 100%;
            }
            .toggle .line1 {
                transform: rotate(-45deg) translate(-6px, 6px);
            }
            .toggle .line2 {
                opacity: 0;
            }
            .toggle .line3 {
                transform: rotate(45deg) translate(-6px, -6px);
            }
        }
    }
}

@media screen and( max-width: $media-breackpoint-up-sm) {
    .nav {
        .container {
            .nav__menu {
                align-items: flex-start;
            }
        }
    }
}