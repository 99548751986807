@import '../../scss/index.scss';
.pulse {
    height: 45px;
    width: 45px;
    background-color: #075e54;
    position: fixed;
    z-index: 30;
    /*   left: 94%; */
    top: 90%;
    margin-right: 0%;
    right: 3%;
    bottom: 0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    a {
        img {
            width: 30px;
            height: 30px;
        }
    }
    &::after,
    &::before {
        content: '';
        position: fixed;
        height: 55px;
        width: 55px;
        background-color: #00bb2b;
        border-radius: 50%;
        z-index: -1;
        opacity: 0.7;
    }
    &::before {
        animation: pulse 2s ease-out infinite;
    }
    &::after {
        animation: pulse 2s in ease-out infinite;
    }
    &:hover {
        cursor: pointer;
    }
}

@keyframes pulse {
    100% {
        transform: scale(1.2);
    }
}