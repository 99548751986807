@import '../../scss/index.scss';
.espacio {
    height: 70px;
}

.container_banner {
    width: 100%;
    height: 60vh;
    background: url('../../assets/png/banner_nuevo.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    /*   img {
        width: 100%;
    } */
    h1 {
        font-family: $bold;
        font-size: 4em;
        font-weight: bold;
        margin-left: 25%;
        margin-right: 25%;
        margin-top: auto;
        /* text-transform: uppercase; */
        margin-bottom: auto;
        text-align: center;
        /* color: white; */
        color: transparent;
        line-height: 1;
        background-image: linear-gradient(to left, $success, $action, $morado, $corporativo, $naranja);
        background-clip: text;
        animation: animate 5s linear infinite;
        background-size: 500%;
    }
    @media screen and( max-width: $media-breackpoint-up-lg) {
        background-position: right;
        h1 {
            margin-left: 20%;
            margin-right: 20%;
            font-size: 3em;
        }
    }
    @media screen and( max-width: $media-breackpoint-up-md) {
        background-position: right;
        h1 {
            margin-left: 0%;
            margin-right: 0%;
        }
    }
    @media screen and( max-width: $media-breackpoint-up-sm) {
        h1 {
            font-size: 2.0em;
        }
    }
}

@keyframes animate {
    0% {
        background-position: 0 100%;
    }
    50% {
        background-position: 100% 0;
    }
    100% {
        background-position: 0 100%;
    }
}