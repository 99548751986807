@import '../../scss/index.scss';
.Composicion {
    background-color: #2594d1;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.9)100%), url('../../assets/png/jump.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    width: 100%;
    color: white;
    .relleno {
        img {
            width: 50px;
            padding-bottom: 10px;
            padding-top: 20px;
        }
        svg {
            width: 70px;
            fill: white !important;
        }
        .texto {
            align-content: center;
            justify-content: center;
            font-family: $gotica;
            margin-top: auto;
            .descripcion {
                color: #2594d1;
                font-weight: bold;
            }
            .contenido {
                font-size: 14px;
                width: 90%;
                text-align: justify;
            }
        }
        .texto_abajo {
            align-content: center;
            justify-content: center;
            font-family: $gotica;
            margin-top: auto;
            margin-bottom: auto;
        }
        h5 {
            width: 90%;
            font-family: $gotica;
            font-weight: 200;
            text-align: justify;
            u {
                color: #2594d1;
                font-weight: bold;
            }
        }
        .calcetalogo {
            img {
                width: 100%;
            }
        }
    }
    .carousel-indicators {
        display: none;
    }
    .carousel-control-prev-icon,
    .carousel-control-next-icon {
        display: none;
    }
    @media screen and( max-width: $media-breackpoint-up-md) {
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.5)100%), url('../../assets/png/jump.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-attachment: fixed;
        .relleno {
            .texto {
                .contenido {
                    width: 100%;
                }
            }
            h5 {
                width: 100%;
            }
        }
    }
}