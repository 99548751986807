@import '../../scss/index.scss';
.conocenos {
    height: 80vh;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    img {
        width: 100px;
        height: auto;
    }
    .image_logo {
        display: flex;
        img {
            margin-top: auto;
            margin-bottom: auto;
            width: 100%;
            border-radius: 15px;
        }
    }
    .texto {
        align-content: center;
        justify-content: center;
        font-family: $gotica;
        margin-top: auto;
        margin-bottom: auto;
        p {
            width: 80%;
            margin-top: auto;
            margin-bottom: auto;
            text-align: justify;
            span {
                font-weight: bold;
            }
        }
        h1 {
            width: 180px;
            span {
                color: #123789;
                font-weight: bold;
            }
        }
        .line {
            height: 5px;
            width: 190px;
            border: 5px solid #123789;
            border-radius: 5px;
            margin-bottom: 15px;
        }
    }
    .bg {
        position: fixed;
        left: 0;
        width: 100%;
        height: 80vh;
        z-index: -20;
        .bg-area li {
            position: absolute;
            list-style: none;
            bottom: -10px;
            left: 5%;
            font-size: 5rem;
            animation: spin 20s linear infinite;
        }
        .bg-area li:nth-child(2) {
            left: 10%;
            animation-delay: 3s;
        }
        .bg-area li:nth-child(3) {
            left: 20%;
            animation-delay: 2s;
        }
        .bg-area li:nth-child(4) {
            left: 30%;
            animation-delay: 4s;
        }
        .bg-area li:nth-child(5) {
            left: 40%;
            animation-delay: 5s;
        }
        .bg-area li:nth-child(6) {
            left: 50%;
            animation-delay: 7s;
        }
        .bg-area li:nth-child(7) {
            left: 60%;
            animation-delay: 8s;
        }
        .bg-area li:nth-child(8) {
            left: 70%;
            animation-delay: 2s;
        }
        .bg-area li:nth-child(9) {
            left: 80%;
            animation-delay: 10s;
        }
        .bg-area li:nth-child(10) {
            left: 90%;
            animation-delay: 4s;
        }
    }
    @media screen and( max-width: 766px) {
        height: 100vh;
    }
    @media screen and( max-width: $media-breackpoint-up-md) {
        padding-bottom: 20px;
        img {
            width: 60px;
            height: auto;
        }
        background-position: right;
        .texto {
            margin-bottom: 20px;
            p {
                width: 100%;
            }
            h1 {
                width: 130px;
            }
            .line {
                height: 5px;
                width: 130px;
                border: 5px solid #123789;
                border-radius: 5px;
                margin-bottom: 15px;
            }
        }
    }
}

@keyframes spin {
    100% {
        transform: translateY(-1200px) rotate(360deg);
        opacity: 0;
    }
}