@import '../../scss/index.scss';
.zigzag {
    width: 100%;
    padding-bottom: 20px;
    background-color: #2594d1;
    position: relative;
    .container__footer {
        padding-top: 50px;
        .logoRef {
            display: flex;
            align-items: center;
            justify-content: center;
            .logo {
                margin-left: auto;
                margin-right: auto;
                width: 150px;
                height: 90px;
            }
        }
        &__info {
            padding-bottom: 20px;
            h4 {
                padding-top: 15px;
                color: white;
                font-family: $gotica;
            }
            &__medios {
                display: flex;
                img {
                    width: 30px;
                    height: 30px;
                }
                p {
                    padding-left: 10px;
                    font-weight: bold;
                    color: white;
                    font-family: $gotica;
                }
            }
            &__social {
                a {
                    text-decoration: none;
                    svg {
                        width: 30px;
                        height: 30px;
                        &:hover {
                            fill: aqua !important;
                        }
                    }
                    p {
                        color: white;
                        text-decoration: none;
                        font-family: $gotica;
                    }
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
            &__menu {
                display: flex;
                align-items: center;
                justify-content: start;
                ul {
                    list-style: none;
                    padding: 0;
                    li {
                        display: flex;
                        align-items: center;
                        justify-content: start;
                        a {
                            margin-bottom: 10px;
                            color: white;
                            text-decoration: none;
                            margin-right: auto;
                            font-family: $gotica;
                            font-size: 20px;
                            &:hover {
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
            .carousel {
                .carousel-inner {
                    .carousel-item {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
            .imageFooter {
                border-radius: 20px;
                width: 100%;
            }
            .carousel-indicators {
                display: none;
            }
            .carousel-control-prev-icon,
            .carousel-control-next-icon {
                display: none;
            }
        }
        .copy {
            padding-top: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #123789;
            p {
                color: #ffffff;
                font-size: 20px;
            }
        }
    }
}

.zigzag::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 24px;
    background: linear-gradient(-135deg, #ffffff 12px, transparent 0%), linear-gradient(135deg, #ffffff 12px, transparent 0%);
    background-size: 30px 60px;
    background-size: 24px;
}